<template>
  <div class="min-h-screen bg-gray-100">
    <!-- 主要内容 -->
    <div class="max-w-full mx-auto px-4 py-4">
      <!-- 统计信息和搜索过滤部分 -->
      <div 
        class="space-y-6 transition-all duration-300 origin-top mb-6" 
        :class="[
          (selectedBed && isCollapsed) ? 'h-0 opacity-0 overflow-hidden' : 'h-auto opacity-100'
        ]"
      >
        <!-- 统计信息 -->
        <div class="grid grid-cols-4 gap-3">
          <div class="bg-white rounded-lg shadow p-3 flex flex-col items-center justify-center">
            <div class="text-gray-500 text-sm">总设备</div>
            <div class="text-xl font-bold">{{ all_total }}</div>
          </div>
          <div class="bg-white rounded-lg shadow p-3 flex flex-col items-center justify-center">
            <div class="text-green-500">在线设备</div>
            <div class="text-2xl font-bold">{{ stats.online }}</div>
          </div>
          <div class="bg-white rounded-lg shadow p-3 flex flex-col items-center justify-center">
            <div class="text-red-500">离线设备</div>
            <div class="text-2xl font-bold">{{ stats.unonline }}</div>
          </div>
          <div class="bg-white rounded-lg shadow p-3 flex flex-col items-center justify-center">
            <div class="text-blue-500">今日报告成功</div>
            <div class="text-2xl font-bold">{{ stats.reports }}</div>
          </div>
        </div>

        <!-- 搜索和过滤区域 -->
        <div class="bg-white rounded-lg shadow-md p-4">
          <!-- 搜索栏部分 -->
          <div class="flex items-center gap-4">
            <!-- 搜索框组 -->
            <div class="flex-1 flex items-center gap-2">
              <div class="relative flex-1 max-w-xl">
                <input
                  v-model="searchText"
                  type="text"
                  placeholder="搜索设备号"
                  class="w-full px-4 py-2 pr-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                <!-- 搜索图标 -->
                <span class="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400">
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </span>
              </div>

              <button
                class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center gap-1"
                @click="handleSearch"
              >
                <span>搜索</span>
              </button>
            </div>

            <!-- 机构筛选组 -->
            <div v-if="showOrgFilter" class="flex items-center gap-3 min-w-[240px]">
              <span class="text-gray-600 whitespace-nowrap">机构筛选:</span>
              <el-select
                v-model="selectedOrg"
                placeholder="请选择机构"
                class="w-40"
                @change="handleOrgFilter"
              >
                <el-option
                  v-for="org in orgOptions"
                  :key="org"
                  :label="org"
                  :value="org"
                />
              </el-select>
            </div>
          </div>

          <!-- 状态筛选部分 -->
          <div class="mt-4 space-y-2">
            <div class="text-sm font-medium text-gray-700">设备状态:</div>
            <div class="flex flex-wrap gap-2">
              <button
                v-for="status in statusOptions"
                :key="status"
                :class="[
                  'px-4 py-1.5 rounded-md text-sm transition-colors',
                  currentStatus === status
                    ? 'bg-blue-100 text-blue-600 ring-1 ring-blue-300'
                    : 'bg-gray-50 text-gray-600 hover:bg-gray-100'
                ]"
                @click="currentStatus = status"
              >
                {{ status }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- 添加控制批量操作的按钮 -->
      <div class="flex items-center gap-4 mb-4">
        <button
          class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          @click="toggleBatchMode"
        >
          {{ isBatchMode ? '退出批量模式' : '进入批量模式' }}
        </button>
        <!-- 全选按钮，仅在批量模式下显示 -->
        <button
          v-if="isBatchMode"
          class="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
          @click="toggleSelectAll"
        >
          {{ isAllSelected ? '取消全选' : '全选' }}
        </button>
        <!-- 下载报告按钮，仅在批量模式下显示 -->
        <button
          v-if="isBatchMode"
          class="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
          @click="prepareDownloadReports"
          :disabled="!selectedBeds.length"
        >
          下载报告
        </button>
      </div>
      <!-- 日期选择弹窗 -->
      <el-dialog
        v-model="showDatePicker"
        title="选择日期范围"
        width="30%"
        destroy-on-close
      >
        <div class="flex flex-col items-center">
          <!-- 添加提示信息 -->
          <div class="w-full mb-4 p-3 bg-blue-50 text-blue-700 rounded-md">
            <div class="flex items-start">
              <svg class="w-5 h-5 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span>
                提示：如果不选择日期范围，将下载所有历史报告数据。
              </span>
            </div>
          </div>
          
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            class="w-full mb-4"
          />
          <div class="flex justify-end w-full mt-4">
            <el-button @click="showDatePicker = false" class="mr-2">取消</el-button>
            <el-button type="primary" @click="confirmDownload">确认下载</el-button>
          </div>
        </div>
      </el-dialog>

      <!-- 修改两列布局部分，移除分页 -->
      <div
        class="flex gap-3 transition-all duration-300 relative"
        :class="[
          selectedBed ? (isCollapsed ? 'h-[calc(100vh-120px)]' : 'h-[calc(100vh-320px)]') : 'h-[calc(100vh-320px)]'
        ]"
      >
        <!-- 折叠按钮移到这里，固定在右上角 -->
        <button
          v-if="selectedBed"
          @click="toggleCollapse"
          class="absolute -top-10 right-0 flex items-center gap-2 px-3 py-1.5 bg-white rounded-md shadow-sm hover:bg-gray-50 transition-colors"
        >
          <span class="text-gray-600">{{ isCollapsed ? '展开筛选' : '收起筛选' }}</span>
          <svg
            class="w-4 h-4 transition-transform text-gray-600"
            :class="{ 'rotate-180': !isCollapsed }"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </button>

        <!-- 左侧设备列表 -->
        <div :class="[
          'grid gap-3 transition-all duration-300 overflow-y-auto',
          selectedBed ? 'w-1/4' : 'w-full',
          selectedBed ? 'grid-cols-1' : 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4',
          'content-start'
        ]">
          <!-- 设备卡片循环 -->
          <div
              v-for="bed in filteredBeds"
              :key="bed.id"
              :ref="el => { if (bed.number === selectedBed?.number) selectedCardRef = el }"
              class="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
              :class="[
                bed.number === selectedBed?.number ? 'ring-2 ring-blue-500 ring-offset-2' : '',
                selectedBed ? 'mx-2' : ''
              ]"
          >
            <!-- 仅在批量模式下显示复选框 -->
            <div class="p-3 flex items-center">
              <input
                v-if="isBatchMode"
                type="checkbox"
                v-model="bed.selected"
                @change="updateSelectedBeds"
                class="mr-2"
              />
              <div
                class="cursor-pointer flex-1"
                @click="bed.hasReport ? showDetail(bed) : null"
                :class="{ 'cursor-not-allowed': !bed.hasReport }"
              >
                <div class="flex justify-between items-center mb-3">
                  <h3 class="text-base font-semibold" >
                    设备：{{ bed.number }}
                  </h3>
                  <div class="flex items-center gap-2">
                    <span :class="[
                      'px-2 py-1 rounded-full text-xs',
                      bed.online ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'
                    ]">
                      {{ bed.online ? '在线' : '离线' }}
                    </span>
                    <span :class="[
                      'px-2 py-1 rounded-full text-xs',
                      bed.hasReport ? 'bg-blue-100 text-blue-600' : 'bg-red-100 text-red-600'
                    ]">
                      {{ bed.hasReport ? '已生成报告' : '未生成报告' }}
                    </span>
                    <div class="flex items-center gap-1.5">
                      <button
                          class="p-1.5"
                          :class="[
                            bed.hasReport
                              ? 'text-blue-500 hover:text-blue-600'
                              : 'text-gray-300 cursor-not-allowed'
                          ]"
                          @click.stop="showDetail(bed)"
                          :disabled="!bed.hasReport"
                          :title="bed.hasReport ? '查看报告' : '暂无报告'"
                      >
                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                      </button>

                      <!-- 添加枕头控制按钮 -->
                      <button
                          class="p-1.5 text-blue-500 hover:text-blue-600"
                          @click.stop="showPillowControl(bed)"
                          v-if="bed.row_data.deviceinfo.type === '干预枕'"
                          title="枕头控制"
                      >
                        <svg t="1733889720447" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2415" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20"><path d="M806.31 1004.13H217.69c-109.1 0-197.83-88.74-197.83-197.81V217.69c0-109.07 88.74-197.82 197.83-197.82h588.62c109.1 0 197.83 88.75 197.83 197.82v588.64c0 109.07-88.73 197.8-197.83 197.8zM217.69 97.57c-66.25 0-120.13 53.88-120.13 120.11v588.64c0 66.22 53.88 120.1 120.13 120.1h588.62c66.25 0 120.13-53.88 120.13-120.1V217.69c0-66.24-53.88-120.11-120.13-120.11H217.69v-0.01z" fill="#1E3A8A" p-id="2416"></path><path d="M494.93 300.08h77.71v420.77h-77.71z" fill="#1E3A8A" p-id="2417"></path><path d="M533.78 324.93m-81.76 0a81.76 81.76 0 1 0 163.52 0 81.76 81.76 0 1 0-163.52 0Z" fill="#93C5FD" p-id="2418"></path><path d="M730.78 300.08h77.71v420.77h-77.71z" fill="#1E3A8A" p-id="2419"></path><path d="M769.62 673.98m-81.76 0a81.76 81.76 0 1 0 163.52 0 81.76 81.76 0 1 0-163.52 0Z" fill="#93C5FD" p-id="2420"></path><path d="M270.58 300.08h77.71v420.77h-77.71z" fill="#1E3A8A" p-id="2421"></path><path d="M309.44 510.47m-81.76 0a81.76 81.76 0 1 0 163.52 0 81.76 81.76 0 1 0-163.52 0Z" fill="#93C5FD" p-id="2422"></path></svg>
                      </button>

                      <button
                          class="p-1.5 text-blue-500 hover:text-blue-600"
                          @click.stop="editDeviceInfo(bed)"
                          title="设置"
                      >
                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="bed.row_data.deviceinfo?.orgbind" class="text-sm text-gray-600 mb-2">
                  机构: <span class="font-medium">{{ bed.row_data.deviceinfo?.orgbind }}</span>
                </div>
                <div v-if="bed.row_data.deviceinfo?.bednumber" class="text-sm text-gray-600 mb-2">
                  床位号: <span class="font-medium">{{ bed.row_data.deviceinfo?.bednumber }}</span>
                </div>
                <div v-if="bed.note" class="text-sm text-gray-500 mb-2">
                  备注信息: <span class="font-medium">{{ bed.note }}</span>
                </div>
                <div class="space-y-2.5">
                  <div class="flex justify-between text-sm">
                    <span class="text-gray-500">报告更新时间</span>
                    <span class="font-medium">{{ bed.lastUpdate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 右侧报告详情 -->
        <div v-if="selectedBed" class="w-3/4">
          <div class="bg-white rounded-lg shadow-md h-full overflow-y-auto">
            <div class="sticky top-0 bg-white p-4 border-b border-gray-200 flex justify-between items-center">
              <h2 class="text-lg font-semibold flex items-center">
                <svg class="w-5 h-5 mr-2 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
                睡眠报告详情 - 设备：{{ selectedBed.number }}
              </h2>
              <div class="flex items-center space-x-3">
                <button
                  @click="closeReport"
                  class="flex items-center px-4 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg transition-all duration-200 space-x-2"
                  :disabled="reportDetailRef?.isLoading"
                >
                  <template v-if="reportDetailRef?.isLoading">
                    <svg class="animate-spin h-5 w-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <span>加载中</span>
                  </template>
                  <template v-else>
                    <span>关闭</span>
                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </template>
                </button>
                <button
                  class="flex items-center px-3 py-2 bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100 transition-colors"
                  @click="handleEditInfo"
                >
                  <svg class="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                  </svg>
                  编辑信息
                </button>
                <!-- 添加问卷按钮 -->
                <button
                  class="flex items-center px-3 py-2 bg-green-50 text-green-600 rounded-md hover:bg-green-100 transition-colors ml-2"
                  @click="showQuestionnaireDialog"
                >
                  <svg class="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                  </svg>
                  填写问卷
                </button>
                <button
                  class="flex items-center px-3 py-2 bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100 transition-colors"
                  @click="printReport"
                >
                  <svg class="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                  </svg>
                  打印
                </button>
              </div>
            </div>
            <div class="p-3"
              v-if="mainViewNameAction === 'reportDetail'"
            >
              <OrgReportDetail
                ref="reportDetailRef"
                v-if="selectedBed?.number"
                :key="`${selectedBed.number}-${selectedBed.lastUpdate}`"
                v-model:device="selectedBed.number"
                :selections="selectedBed.row_data"
                :date="selectedBed.lastUpdate"
                :stage="2"
                @date-change="handleDateChange"
              />
            </div>
            <div v-else-if="mainViewNameAction === 'pillow_control'">
              <OrgPillowControl
                :device="selectedBed.number"
                @pillow-control="handlePillowControl"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- 将分页移到外部，作为独立模块 -->
      <div
        class="mx-auto mt-6 flex justify-center p-4 bg-white rounded-lg shadow-sm border border-slate-200"
        :class="[isNavCollapsed ? 'max-w-[120rem]' : 'max-w-[100rem]']"
      >
        <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :total="total"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next"
          @size-change="handlePageSizeChange"
          @current-change="handleCurrentChange"
          background
          class="flex items-center justify-center"
        />
      </div>
    </div>
  </div>
  <!-- 添加编辑弹窗 -->
<el-dialog
    v-model="showEditModal"
    title="编辑信息"
    width="50%"
>
  <div class="space-y-4">
    <!-- 基本信息表单 -->
    <el-form :model="patientForm" label-width="100px">
      <!-- 一行：名、性别、民族 -->
      <div class="flex gap-4">
        <el-form-item label="姓名:" class="w-64">
          <el-input v-model="patientForm.name" placeholder="请输入姓名" />
        </el-form-item>

        <el-form-item label="性别:" class="w-48">
          <el-select
            v-model="patientForm.gender"
            placeholder="请选择..."
            class="w-full"
          >
            <el-option label="男" value="男" />
            <el-option label="女" value="女" />
          </el-select>
        </el-form-item>

        <el-form-item label="民族:" class="flex-1">
          <el-select
            v-model="patientForm.ethnicity"
            filterable
            placeholder="请选择或搜索民族"
            class="w-full"
          >
            <el-option
                v-for="ethnic in ethnicities"
                :key="ethnic"
                :label="ethnic"
                :value="ethnic"
            />
          </el-select>
        </el-form-item>
      </div>

      <!-- 第二行：出生日期、年龄 -->
      <div class="flex gap-4">
        <el-form-item label="出生日期:" class="w-64">
          <el-date-picker
              v-model="patientForm.birthDate"
              type="date"
              @update:modelValue="calculateAge"
              placeholder="选择日期"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              class="w-full"
              :clearable="false"
              :disabledDate="disabledDate"
              :shortcuts="shortcuts"
              :locale="zhCn"
          />
        </el-form-item>

        <el-form-item label="年龄:" class="w-48">
          <span class="text-gray-500">{{ patientForm.age || '--' }} 岁</span>
        </el-form-item>
      </div>

      <!-- 第三行：身高、体重、BMI -->
      <div class="flex gap-6">
        <el-form-item label="身高:" class="flex-1">
    <div class="flex items-center gap-2">
      <el-input-number
          v-model="patientForm.height"
          :min="0"
          @change="calculateBMI"
          class="w-40"
          controls-position="right"
          placeholder="请输入身高"
      />
      <span class="text-gray-500 ml-2">cm</span>
    </div>
  </el-form-item>


  <el-form-item label="体重:" class="flex-1">
    <div class="flex items-center gap-2">
      <el-input-number
          v-model="patientForm.weight"
          :min="0"
          @change="calculateBMI"
          class="w-40"
          controls-position="right"
          placeholder="请输入体重"
      />
      <span class="text-gray-500 ml-2">kg</span>
    </div>
  </el-form-item>
  <el-form-item label="BMI:" class="w-1/3">
          <span class="text-gray-500">{{ patientForm.bmi || '--' }}</span>
        </el-form-item>
      </div>

      <!-- 自定义字段个字段单占一行 -->
      <div v-for="(field, index) in customFields" :key="index">
        <el-form-item :label="field.label || '自定义字段'" class="custom-field-item">
          <div class="flex flex-col gap-2 w-full">
            <div v-if="field.error" class="text-red-500 text-sm mb-1">
              {{ field.error }}
            </div>

            <template v-if="!field.label || field.isEditing">
              <div class="flex-1 flex items-center gap-4">
                <el-input
                  v-model="field.tempLabel"
                  placeholder="字段名称"
                  class="w-1/3"
                  @input="field.error = ''"
                />
                <div class="flex items-center gap-3 w-1/3">
                  <el-input
                    v-model="field.value"
                    placeholder="字段值"
                    class="w-30"
                    @input="field.error = ''"
                  />
                  <el-input
                    v-model="field.unit"
                    placeholder="单位"
                    class="w-20"
                  />
                </div>
              </div>
              <div class="flex items-center gap-1">
                <button
                  type="button"
                  @click="validateAndConfirmField(field, index)"
                  class="text-blue-500 hover:text-blue-600 p-1.5"
                  title="确认"
                >
                  <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                </button>
                <button
                  @click="removeCustomField(index)"
                  class="text-red-500 hover:text-red-600 p-1.5"
                  title="删除"
                >
                  <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </template>
            <template v-else>
              <div class="flex-1 flex items-center">
                <div class="text-gray-900">{{ field.value }}</div>
                <div class="text-gray-600 ml-1">{{ field.unit }}</div>
                <div class="flex items-center gap-1 ml-2">
                  <button
                    @click="editFieldLabel(field)"
                    class="text-blue-500 hover:text-blue-600 p-1.5"
                    title="编辑"
                  >
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </button>
                  <button
                    @click="removeCustomField(index)"
                    class="text-red-500 hover:text-red-600 p-1.5"
                    title="删除"
                  >
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
            </template>
          </div>
        </el-form-item>
      </div>

      <!-- 修改添加自定义字段按钮样式 -->
      <el-button
        type="primary"
        @click="addCustomField"
        class="mt-4"
      >
        <span class="flex items-center gap-1">
          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
          </svg>
          添加自定义字段
        </span>
      </el-button>
    </el-form>
  </div>

  <template #footer>
    <span class="dialog-footer">
      <el-button @click="showEditModal = false">取消</el-button>
      <el-button type="primary" @click="savePatientInfo">保存</el-button>
    </span>
  </template>
</el-dialog>

<!-- 添加设备信息编辑弹窗 -->
<el-dialog
  v-model="showDeviceEditModal"
  title="设备信息设置"
  width="400px"
>
  <el-form :model="deviceForm" label-width="80px">
    <el-form-item label="床位号">
      <el-input v-model="deviceForm.bedNumber" placeholder="请输入床位号" />
    </el-form-item>
    <el-form-item label="备注信息">
      <el-input
        v-model="deviceForm.note"
        type="textarea"
        :rows="3"
        placeholder="请输备注信息"
      />
    </el-form-item>
  </el-form>
  <template #footer>
    <span class="dialog-footer">
      <el-button @click="showDeviceEditModal = false">取消</el-button>
      <el-button type="primary" @click="saveDeviceInfo">保存</el-button>
    </span>
  </template>
</el-dialog>

    <!-- 密码修改提示框 -->
    <el-dialog
      title="密码修改"
      v-model="showPasswordDialog"
      width="400px"
    >
      <p>您的密码是初始密码，请修改密码。</p>
      <el-input
        v-model="newPassword"
        type="password"
        placeholder="请输入新密码"
        show-password
      />
      <span class="text-red-500" v-if="errorMessage">{{ errorMessage }}</span>
      <span class="text-gray-500">请确保密码至少6位。</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showPasswordDialog = false">取消</el-button>
        <el-button type="primary" @click="resetPassword">确认修改</el-button>
      </span>
    </el-dialog>

    <!-- 添加问卷选择对话框 -->
    <el-dialog
      v-model="showQuestionnaire"
      title="选择问卷类型"
      width="400px"
      destroy-on-close
      center
    >
      <div class="flex flex-col gap-4">
        <button
          class="w-full p-4 text-left bg-white border rounded-lg hover:bg-gray-50 transition-colors flex items-center justify-between"
          @click="openQuestionnaire('psqi')"
        >
          <div>
            <div class="font-medium">匹兹堡睡眠质量指数 (PSQI)</div>
            <div class="text-sm text-gray-500">评估最近一个月的睡眠质量</div>
          </div>
          <svg class="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>
        
        <button
          class="w-full p-4 text-left bg-white border rounded-lg hover:bg-gray-50 transition-colors flex items-center justify-between"
          @click="openQuestionnaire('ess')"
        >
          <div>
            <div class="font-medium">嗜睡量表 (ESS)</div>
            <div class="text-sm text-gray-500">评估日间嗜睡程度</div>
          </div>
          <svg class="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </el-dialog>

    <!-- PSQI 问卷对话框 -->
    <el-dialog
      v-model="showPSQI"
      width="80%"
      destroy-on-close
      :close-on-click-modal="false"
    >
      <PSQIQuestion 
        :device-id="selectedBed?.number"
        :date="currentReportDate"
        @close="showPSQI = false"
        @submit="handleQuestionnaireSubmit"
      />
    </el-dialog>

    <!-- ESS 问卷对话框 -->
    <el-dialog
      v-model="showESS"
      width="80%"
      destroy-on-close
      :close-on-click-modal="false"
    >
      <ESSQuestion 
        :device-id="selectedBed?.number"
        :date="currentReportDate"
        @close="showESS = false"
        @submit="handleQuestionnaireSubmit"
      />
    </el-dialog>
</template>

<script setup>
import {computed, onMounted, ref, nextTick, watch} from 'vue'
import {createAxiosInstance, wdb_get_history_new,request_data} from "@/plugins/axios.js";
import {formatTimestamp} from "@/plugins/utils/utils.js";
import OrgReportDetail from "@/components/OrgReportDetail.vue";
import OrgPrintReport from "@/components/OrgPrintReport.vue";
import {useStore} from "vuex";
import { eventBus, sendCmd } from '@/plugins/websocket';
import {useRouter} from 'vue-router';
import md5 from 'md5';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
const router = useRouter();
// import PSQIQuestion from '@/components/PSQIQusetion.vue'
import PSQIQuestion from '@/components/PSQIQuestion.vue'
import ESSQuestion from '@/components/ESSQuestion.vue'
import { ElMessage } from 'element-plus'
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElSelect,
  ElOption,
  ElDatePicker,
  ElInputNumber,
  ElButton
} from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import OrgPillowControl from '@/components/OrgPillowControl.vue'
import { wdb_get_new } from '@/plugins/axios.js';
import { useRoute } from 'vue-router';
const showPasswordDialog = ref(false);
const newPassword = ref('');
const errorMessage = ref('');

// 获取路由实例
const route = useRoute();

const faultData = ref([]);

const store = useStore();

const user = computed(() => store.state.user);
console.log(user)
// 状态定义
const searchText = ref(route.query.deviceID || '');
const currentStatus = ref('全部')
const showModal = ref(false)
const selectedBed = ref(null)
const statusOptions = ['全部', '设备在线', '已生成报告']
let encodedFilterStr = '';
const currentPage = ref(1);
const rows = ref([]);
const total = ref(0);
const all_total = ref(0);
const flag = ref(0);
// 义 emit
const emit = defineEmits(['view-report'])

// 添加日期格式化工具函数
const formatDateValue = (dateValue) => {
  if (!dateValue) return '';

  try {
    // 处理时间戳（假设是秒级时间戳）
    if (typeof dateValue === 'number') {
      const date = new Date(dateValue * 1000);
      return date.toISOString().split('T')[0];
    }

    // 处理 YYYY-MM-DD 格式的字符串
    if (typeof dateValue === 'string' && dateValue.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return dateValue;
    }

    // 处理其他期字串
    const date = new Date(dateValue);
    if (!isNaN(date.getTime())) {
      return date.toISOString().split('T')[0];
    }

    return '';
  } catch (error) {
    console.error('日期格式化错误:', error);
    return '';
  }
};

// 修改 selectedBed 的定义，添加一个新的 ref 来存储当前选中的日期
const currentReportDate = ref(null) // 新增：用于跟踪当前选中的报日期

// 修改 showDetail 函数
const showDetail = async (bed) => {
  try {
    mainViewNameAction.value = 'reportDetail'
    selectedBed.value = bed;
    currentReportDate.value = bed.lastUpdate; // 初始化当前报告日期
    isCollapsed.value = true; // 确保展示报告时默认折
    // 触发折叠导栏事件
    emit('view-report');

    const axiosInstance = createAxiosInstance('计算服务器1');

    // 格式化日期 - 直接使用日期部分
    let formattedLastUpdate;

    if (bed.lastUpdate instanceof Date) {
      formattedLastUpdate = bed.lastUpdate.toISOString().split('T')[0];
    } else {
      // 直接获取日期部分
      formattedLastUpdate = bed.lastUpdate.split(' ')[0];
    }

    // 请求报告信息
    const response = await axiosInstance.get(`report_info/${bed.number}/${formattedLastUpdate}/`);

    if (response.data.status === 'success' && response.data.data) {
      const { basicInfo, customFields: customFieldsData } = response.data.data;

      // 确保 row_data 对象存在
      if (!bed.row_data) {
        bed.row_data = {};
      }

      // 确保 userinfo 对象存在
      if (!bed.row_data.userinfo) {
        bed.row_data.userinfo = {};
      }

      // 更新 userinfo
      bed.row_data.userinfo = {
        ...bed.row_data.userinfo, // 保留原有数据
        username: basicInfo.name || bed.row_data.userinfo.username || '',
        sex: basicInfo.gender || bed.row_data.userinfo.sex || '',
        ethnicity: basicInfo.ethnicity || bed.row_data.userinfo.ethnicity || '',
        birthday: formatDateValue(basicInfo.birthDate) || bed.row_data.userinfo.birthday || '',
        age: basicInfo.age || bed.row_data.userinfo.age || '',
        height: basicInfo.height || bed.row_data.userinfo.height || null,
        weight: basicInfo.weight || bed.row_data.userinfo.weight || null,
        bmi: basicInfo.bmi || bed.row_data.userinfo.bmi || '',
        customData: customFieldsData || {}
      };

      // 强制更新 selectedBed 触发重渲染
      selectedBed.value = { ...bed };

    }

    // 滚动到选中的卡片
    nextTick(() => {
      if (selectedCardRef.value) {
        selectedCardRef.value.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    });
  } catch (error) {
    console.error('获取报告信息失败:', error);
  }
};

const editBed = (bed) => {
  console.log('编辑床位:', bed)
}

// 添加 mainViewNameAction ref
const mainViewNameAction = ref('reportDetail')

const showPillowControl = (bed) => {
  selectedBed.value = bed
  mainViewNameAction.value = 'pillow_control'
  isCollapsed.value = true
}

// 添加处理枕头控制事件的方法
const handlePillowControl = (controlData) => {
  console.log('枕头控制事件:', controlData)
  // 这里可以添加其他处理逻辑
}

// 修改 closeReport 方法
const closeReport = () => {
  selectedBed.value = null
  mainViewNameAction.value = null
  isCollapsed.value = true
  emit('expand-nav')
}

const handleSearch = () => {
  currentPage.value = 1 // 重置页码
  fetchData()
}
const filteredFaultData = computed(() => {
  return faultData.value.filter(item => item.value !== 0);
});
const reports = ref(0)

async function getReports(device) {
  reports.value = 0;
  const values = await wdb_get_history_new('计算服务器1', '报告统计天', device, 60);
  return values.filter(item => item !== null).length;
}

// 构建过滤字符串
const buildFilterString = () => {
  let filterParts = []

  // 添加搜索条件
  if (searchText.value) {
    filterParts.push(`device*${searchText.value}`)
  }

  // 添加机构条件
  if (showOrgFilter.value && selectedOrg.value !== '全部') {
    filterParts.push(`deviceinfo.orgbind=${selectedOrg.value}`)
  }

  // 添加状态条件
  if (currentStatus.value === '设备在线') {
    filterParts.push('all_tags.在线')
  } else if (currentStatus.value === '已生成报告') {
    filterParts.push('all_tags.报告成功')
  }

  // 用 AND 连接所有条件
  return filterParts.join(' AND ')
}

// 修改获取数据的方法
const fetchData = async () => {
  try {
    const axiosInstance = createAxiosInstance('数据服务器');
    const filterStr = buildFilterString()
    const encodedFilterStr = encodeURIComponent(filterStr)

    const response = await request_data(
      '/device/?filter=' + encodedFilterStr + 
      '&page=' + currentPage.value + 
      '&pagesize=' + pageSize.value
    );

    const nodesData = response.nodes;
    for (const node of nodesData) {
      node.reports = await getReports(node.device);
    }
    rows.value = nodesData;
    total.value = response.count;
    if (flag.value === 0) {
      all_total.value = total.value
      stats.value.unonline = all_total.value - stats.value.online

      flag.value = 1
    }
  } catch (error) {
    console.error('获取数据失败:', error);
    ElMessage.error('获取数据失败')
  }
};

// 数据转换
const beds = computed(() => {
  return rows.value.map((row, index) => ({
        id: index, // 使用索引作为id
        number: row.device,
        bedNumber: row.deviceinfo?.bedNumber || '',
        note: row.deviceinfo?.note || '',
        online: row['设备和网络']['在线'],
        hasReport: row.reports > 0,
        lastUpdate: formatTimestamp(row.sleep_status?.报告生成),
        row_data: {"userinfo":row.userinfo,"deviceinfo":row.deviceinfo}
      }
  ))

})

// 算属性
const onlineBeds = computed(() => beds.value.filter(bed => bed.online).length)
const offlineBeds = computed(() => beds.value.filter(bed => !bed.online).length)
const bedsWithReport = computed(() => beds.value.filter(bed => bed.hasReport).length)

// const filteredBeds = computed(() => {
//   let filtered = beds.value
//   if (searchText.value) {
//     const searchLower = searchText.value.toLowerCase()
//     filtered = filtered.filter(bed =>
//         bed.number.toLowerCase().includes(searchLower) ||
//         (bed.row_data.deviceinfo?.bednumber || '').toLowerCase().includes(searchLower)
//     )
//   }
//   if (currentStatus.value !== '全部') {
//     switch (currentStatus.value) {
//       case '设备在线':
//         filtered = filtered.filter(bed => bed.online)
//         break
//       case '设备离线':
//         filtered = filtered.filter(bed => !bed.online)
//         break
//       case '已生成报告':
//         filtered = filtered.filter(bed => bed.hasReport)
//         break
//       case '未生成报告':
//         filtered = filtered.filter(bed => !bed.hasReport)
//         break
//     }
//   }
//   return filtered
// })

const logout = () => {
  router.push('/login');
}
const stats = ref({'online': 0, 'reports': 0, 'unonline': 0})
function on_fetchlabel (data) {
  const responses = data.message.data;
  try {
    faultData.value = responses.fault
    stats.value.online = responses.normal[1].value;
    stats.value.reports = responses.normal[0].value
    console.log(responses)
    if (all_total.value > 0) {
      stats.value.unonline = all_total.value - stats.value.online
    }
  } catch (error) {
    console.error('Failed to fetch data:', error);
  }
}
async function fetchTagData () {
  const filters = ['报告成功',"在线","设备离线"]
  sendCmd('fetchlabel', { fault: filters, normal: ['报告成功',"在线","设备离线"] }).then(() => {
    console.log('fetchlabel from data');
  });
}


// 修改 onMounted 钩子
onMounted(async () => {
  console.log('组件挂载，开始初始化')
  const password = localStorage.getItem('password');
  console.log(password)
  // 假设这里的校验逻辑是检查密码是否有效
  if(password==='false'){
    showPasswordDialog.value = true; // 弹出修改密码的对话框

  }
  await nextTick()
  
  // 如果有设备ID参数，自动进行搜索
  if (route.query.deviceID) {
    searchText.value = route.query.deviceID;
    // 确保设置了搜索文本后再获取数据
    await fetchData();
    
    // 如果找到对应的设备，自动显示详情
    const targetBed = beds.value.find(bed => bed.number === route.query.deviceID);
    if (targetBed && targetBed.hasReport) {
      await showDetail(targetBed);
    }
  } else {
    // 没有设备ID参数时，正常初始化
    if (showOrgFilter.value) {
      await fetchOrgOptions();
    }
    
    eventBus.on('fetchlabel', on_fetchlabel);
    
    await Promise.all([
      fetchTagData(),
      fetchData()
    ]);
    
    startStatsRefresh();
  }
});

// 添加路由参数变化的监听
watch(
  () => route.query.deviceID,
  async (newDeviceID) => {
    if (newDeviceID) {
      searchText.value = newDeviceID;
      await fetchData();
      
      // 找到对应的设备并显示详情
      const targetBed = beds.value.find(bed => bed.number === newDeviceID);
      if (targetBed && targetBed.hasReport) {
        await showDetail(targetBed);
      }
    }
  }
);

// 添加折叠状态
const isCollapsed = ref(true)

// 添加新的 ref
const selectedCardRef = ref(null)

const printReport = () => {
  let formattedDate;
if (typeof currentReportDate.value === 'string') {
  // 直接提取日期部分
  formattedDate = currentReportDate.value.split(' ')[0];
    } else if (currentReportDate.value instanceof Date) {
      formattedDate = currentReportDate.value.toISOString().split('T')[0];
    } else {
      const date = new Date(currentReportDate.value);
      formattedDate = date.toISOString().split('T')[0];
    }
  const url = router.resolve({
    name: 'report-detail',
    query: {
      device: selectedBed.value.number,
      date: formattedDate,
      selections: JSON.stringify(selectedBed.value.row_data),
            print: 'true' // 添加打印标记

    }
  }).href;
  
  window.open(url, '_blank');
};

// 加新的响应式变
const showEditModal = ref(false)
const patientForm = ref({
  name: '',
  gender: '',
  birthDate: '',
  age: '',
  ethnicity: '',
  height: null,
  weight: null,
  bmi: '',
})
const customFields = ref([])

// 确保民族列表已定义
const ethnicities = [
    "汉族", "蒙古族", "回族", "藏族", "维吾尔族",
    "苗族", "彝族", "壮族", "布依族", "朝鲜族",
    "满族", "侗族", "瑶族", "白族", "土家族",
    "哈尼族", "哈萨克族", "傣族", "黎族", "傈僳族",
    "佤族", "畲族", "高山族", "拉祜", "水族",
    "东乡族", "纳西族", "景颇族", "柯尔克孜族", "土族",
    "达斡尔族", "仫佬", "羌族", "布朗族", "撒族",
    "毛南族", "仡佬族", "锡伯族", "阿昌族", "普米族",
    "塔吉克族", "塔塔尔", "乌孜克族", "俄罗斯族", "鄂温克族",
    "德昂", "保安族", "裕固族", "京族", "塔塔尔族",
    "独龙族", "伦春族", "赫哲族", "��巴族", "珞巴族",
    "基诺族"
]


// 计算年龄
const calculateAge = () => {
  if (patientForm.value.birthDate) {
    const birthDate = new Date(patientForm.value.birthDate)
    const today = new Date()
    let age = today.getFullYear() - birthDate.getFullYear()
    const monthDiff = today.getMonth() - birthDate.getMonth()
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    patientForm.value.age = age.toString()
  } else {
    patientForm.value.age = '0'
  }
}

// 计算BMI
const calculateBMI = () => {
  const { height, weight } = patientForm.value
  if (height && weight) {
    const heightInMeters = height / 100
    const bmi = (weight / (heightInMeters * heightInMeters)).toFixed(2)
    patientForm.value.bmi = bmi
  }
}

// 修改添加自定义字段的方法
const addCustomField = () => {
  customFields.value.push({
    label: '', 
    tempLabel: '', 
    value: '', 
    unit: '',
    isEditing: true,
    error: ''
  })
}

// 修改确认字段的方法
const validateAndConfirmField = (field, index) => {
  // 清除之前的错误信息
  field.error = ''
  
  if (!field.tempLabel?.trim()) {
    field.error = '请输入字段名称'
    return
  }
  if (!field.value?.trim()) {
    field.error = '请输入字段值'
    return
  }
  
  // 检查字段名称是否重复
  const isDuplicate = customFields.value.some((f, i) => 
    i !== index && 
    f.label && 
    !f.isEditing && 
    f.label.toLowerCase() === field.tempLabel.trim().toLowerCase()
  )
  
  if (isDuplicate) {
    field.error = '字段名称已存在'
    return
  }
  
  // 如果验证都通过，则确认字段
  field.label = field.tempLabel.trim()
  field.isEditing = false
}

// 修改编辑字段的方法
const editFieldLabel = (field) => {
  field.tempLabel = field.label
  field.isEditing = true
}

// 修改保存方法中的自定义字段处理
const savePatientInfo = async () => {
  try {
    // 将自定义字段数组转换为对象
    const customFieldsObject = customFields.value.reduce((acc, field) => {
      if (field.label && field.value) {
        acc[field.label] = {
          value: field.value,
          unit: field.unit || ''
        }
      }
      return acc
    }, {})

    // 合并基本信息和自定义字段
    const formData = {
      deviceId: selectedBed.value.number, // 添加设备ID
      basicInfo: {
        name: patientForm.value.name,
        gender: patientForm.value.gender,
        ethnicity: patientForm.value.ethnicity,
        birthDate: patientForm.value.birthDate,
        age: patientForm.value.age,
        height: patientForm.value.height,
        weight: patientForm.value.weight,
        bmi: patientForm.value.bmi
      },
      customFields: customFieldsObject
    }

    const axiosInstance = createAxiosInstance('计算服务器1');
    
    let formattedDate;
    // 使用 currentReportDate 而不是 selectedBed.lastUpdate
    if (typeof currentReportDate.value === 'string') {
      // 直接提取日期部分
      formattedDate = currentReportDate.value.split(' ')[0];
        } else if (currentReportDate.value instanceof Date) {
          formattedDate = currentReportDate.value.toISOString().split('T')[0];
        } else {
          const date = new Date(currentReportDate.value);
          formattedDate = date.toISOString().split('T')[0];
        }
      console.log(formattedDate)
      const response = await axiosInstance.post(`/report_info_edit/${selectedBed.value.number}/${formattedDate}/`, formData,
      {
        headers: {
          'Content-Type': 'application/json'
        }}
      )
    
    if(response.status === 200) {
      ElMessage.success('保存成功')
      showEditModal.value = false
      
      // 更新 selectedBed 中的用户信息
      if (selectedBed.value && selectedBed.value.row_data) {
        selectedBed.value.row_data.userinfo = {
          ...selectedBed.value.row_data.userinfo,
          username: patientForm.value.name,
          sex: patientForm.value.gender,
          ethnicity: patientForm.value.ethnicity,
          birthday: patientForm.value.birthDate,
          age: patientForm.value.age,
          height: patientForm.value.height,
          weight: patientForm.value.weight,
          bmi: patientForm.value.bmi,
          customData: customFields.value.reduce((acc, field) => {
            if (field.label && field.value) {
              acc[field.label] = field.value;
            }
            return acc;
          }, {})
        }
        
        // 强制更新 selectedBed 以触发重渲染
        selectedBed.value = { ...selectedBed.value }
      }
      
      await fetchData()
    }
  } catch (error) {
    console.error('保存失败:', error)
    ElMessage.error('保存失败,请重试')
  }
}

// 删除自定义字段
const removeCustomField = (index) => {
  customFields.value.splice(index, 1)
}

async function handleEditInfo() {
  try {
    const axiosInstance = createAxiosInstance('计算服务器1');
    let formattedDate;
if (typeof currentReportDate.value === 'string') {
  // 直接提取日期部分
  formattedDate = currentReportDate.value.split(' ')[0];
    } else if (currentReportDate.value instanceof Date) {
      formattedDate = currentReportDate.value.toISOString().split('T')[0];
    } else {
      const date = new Date(currentReportDate.value);
      formattedDate = date.toISOString().split('T')[0];
    }
    console.log(formattedDate)
    const response = await axiosInstance.get(`report_info/${selectedBed.value.number}/${formattedDate}/`);
    
    if (response.data.status === 'success' && response.data.data) {
      const { basicInfo, customFields: customFieldsData } = response.data.data;
      
      // 处理自定义字段数据
      customFields.value = Object.entries(customFieldsData || {}).map(([label, data]) => ({
        label,
        value: data.value || '',
        unit: data.unit || '',
        tempLabel: label,
        isEditing: false
      }));
      
      patientForm.value = {
        name: basicInfo.name || '',
        gender: basicInfo.gender || '',
        ethnicity: basicInfo.ethnicity || '',
        birthDate: formatDateValue(basicInfo.birthDate), // 使用格式化函数处理日期
        age: basicInfo.age || '',
        height: basicInfo.height || null,
        weight: basicInfo.weight || null,
        bmi: basicInfo.bmi || ''
      };
      
      showEditModal.value = true;
      
    } else {
      const bedUserInfo = selectedBed.value.row_data?.userinfo;
      if (bedUserInfo) {
        patientForm.value = {
          name: bedUserInfo.username || '',
          gender: bedUserInfo.sex || '',
          ethnicity: bedUserInfo.ethnicity || '',
          birthDate: formatDateValue(bedUserInfo.birthday), // 用格式化函处理日期
          age: bedUserInfo.age || '',
          height: bedUserInfo.height || null,
          weight: bedUserInfo.weight || null,
          bmi: bedUserInfo.bmi || ''
        };
      } else {
        patientForm.value = {
          name: '',
          gender: '',
          ethnicity: '',
          birthDate: '',
          age: '',
          height: null,
          weight: null,
          bmi: ''
        };
      }
      customFields.value = [];
    }
    
    showEditModal.value = true;
    
  } catch (error) {
    console.error('获取报告信息失败:', error);
    ElMessage.error('获取报告信息失败');
  }
}

// 监听 height 和 weight 的变化
watch([() => patientForm.value.height, () => patientForm.value.weight], ([newHeight, newWeight]) => {
  calculateBMI(newHeight, newWeight);
});

// 添加一个新的方法来处理日期更新
const handleDateChange = async (newDate) => {
  let formattedLastUpdate;
    if (newDate instanceof Date) {
      formattedLastUpdate = newDate.toISOString().split('T')[0];
    } else {
      const [datePart] = newDate.split(' ');
      formattedLastUpdate = datePart;
    }
  currentReportDate.value = formattedLastUpdate;
  await fetchData()
}

// 添加切换折叠态的方法
const toggleCollapse = () => {
  isCollapsed.value = !isCollapsed.value
}

const reportDetailRef = ref(null)

// 在 script setup 中添加
const showDeviceEditModal = ref(false)
const deviceForm = ref({
  deviceId: '',
  bedNumber: '',
  note: ''
})

// 添加编辑设备信息的方法
const editDeviceInfo = (bed) => {
  deviceForm.value = {
    deviceId: bed.number,
    bedNumber: bed.row_data.deviceinfo?.bednumber || '',
    note: bed.row_data.deviceinfo?.note || ''
  }
  showDeviceEditModal.value = true
}

// 添加保存设备信息的方法
const saveDeviceInfo = async () => {
  try {
    const axiosInstance = createAxiosInstance('数据服务器')
    const response = await axiosInstance.get(`/updatedevice/${deviceForm.value.deviceId}/?bednumber=${deviceForm.value.bedNumber}&note=${deviceForm.value.note}`)
    
    if (response.status === 200) {
      // 更新本地数据
      const bedToUpdate = beds.value.find(bed => bed.number === deviceForm.value.deviceId)
      if (bedToUpdate) {
        // 确保 row_data 和 deviceinfo 对象存在
        if (!bedToUpdate.row_data) bedToUpdate.row_data = {}
        if (!bedToUpdate.row_data.deviceinfo) bedToUpdate.row_data.deviceinfo = {}
        
        // 更床位号和备注信息
        bedToUpdate.row_data.deviceinfo.bednumber = deviceForm.value.bedNumber
        bedToUpdate.note = deviceForm.value.note
        
        // 如果这是当前选中的设备，也更 selectedBed
        if (selectedBed.value?.number === deviceForm.value.deviceId) {
          selectedBed.value = { ...bedToUpdate }
        }
      }
      
      ElMessage.success('保存成功')
      showDeviceEditModal.value = false
    }
  } catch (error) {
    console.error('保存失败:', error)
    ElMessage.error('保存失败，请重试')
  }
}

// 添加分页相关的响应式变量
const pageSize = ref(50)

// 添加分页处理方法
const handlePageSizeChange = (newSize) => {
  pageSize.value = newSize
  currentPage.value = 1 // 重置到第一页
  fetchData()
}

const handleCurrentChange = (newPage) => {
  currentPage.value = newPage
  fetchData()
}

// 添加机构相关的响应式变量
const selectedOrg = ref('全部')
const orgOptions = ref(['全部'])
const showOrgFilter = computed(() => ['管理', '机构管理'].includes(user.value.role))

// 添加用户角色相关的逻辑
// const showOrgFilter = computed(() => {
//   return user.value.role === '机构管理' || user.value.role === '管理'
// })

// 修改获取机构列表的方法
const fetchOrgOptions = async () => {
  try {
    console.log('当前用户角色:', user.value.role) // 添加日志
    
    if (user.value.role === '机构管理') {
      // 机构管理角色使用固定列表
      const fixedOrgs = ['全部', '上海六院', '华山医院', '大连市医院', '中南大学湘雅二医院', '华西医院']
      console.log('设置固定机构列表:', fixedOrgs) // 添加日志
      orgOptions.value = fixedOrgs
    } else if (user.value.role === '管理') {
      // 管理角色从服务器获取完整列表
      const orgs = await wdb_get_new('数据服务器', 'org')
      orgOptions.value = ['全部', ...orgs]
    }
  } catch (error) {
    console.error('获取机构列表失败:', error)
    ElMessage.error('获取机构列表失败')
    // 确保至少有"全部"选项
    orgOptions.value = ['全部']
  }
}

// 添加 startStatsRefresh 函数
const startStatsRefresh = () => {
  // 如果需要定时刷新统计数据，可以在这里添加逻辑
  // 例如：
  setInterval(async () => {
    await fetchTagData();
  }, 60000); // 每分钟刷新一次
};

// 修改 onMounted 钩子
onMounted(async () => {
  console.log('组件挂载，开始初始化') // 添加日志
  
  // 确保用户信息已加载
  await nextTick()
  
  // 如果需要显示机构筛选，则获取机构列表
  if (showOrgFilter.value) {
    console.log('需要显示机构筛选，开始获取机构列表') // 添加日志
    await fetchOrgOptions()
  }
  
  // 设置事件监听并获取数据
  eventBus.on('fetchlabel', on_fetchlabel)
  
  // 并行获取统计数据和设备列表
  await Promise.all([
    fetchTagData(),
    fetchData()
  ])
  
  // 启动统计数据的定时刷新
  startStatsRefresh()
})

// 添加用户角色变化的监听
watch(() => user.value.role, async (newRole) => {
  console.log('用户角色变化:', newRole) // 添加日志
  if (showOrgFilter.value) {
    await fetchOrgOptions()
  }
}, { immediate: true })

// 修改 filteredBeds 计算属性
const filteredBeds = computed(() => {
  let filtered = beds.value
  
  // 搜索过滤
  if (searchText.value) {
    const searchLower = searchText.value.toLowerCase()
    filtered = filtered.filter(bed =>
      bed.number.toLowerCase().includes(searchLower) ||
      (bed.row_data.deviceinfo?.bednumber || '').toLowerCase().includes(searchLower)
    )
  }
  
  // 状态过滤
  if (currentStatus.value !== '全部') {
    switch (currentStatus.value) {
      case '设备在线':
        filtered = filtered.filter(bed => bed.online)
        break
      case '设备离线':
        filtered = filtered.filter(bed => !bed.online)
        break
      case '已生成报告':
        filtered = filtered.filter(bed => bed.hasReport)
        break
      case '未生成报告':
        filtered = filtered.filter(bed => !bed.hasReport)
        break
    }
  }
  
  // 机构���滤 - 仅在显示机构筛选时生效
  if (showOrgFilter.value && selectedOrg.value !== '全部') {
    filtered = filtered.filter(bed => 
      bed.row_data.deviceinfo?.orgbind === selectedOrg.value
    )
  }
  
  return filtered
})

// 在组件挂载时获取机构列表
onMounted(async () => {
  if (showOrgFilter.value) {
  }
})

// 添加防抖函数
const debounce = (fn, delay) => {
  let timer = null
  return function (...args) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, args)
    }, delay)
  }
}

// 使用防抖处理搜索输入
const debouncedSearch = debounce(() => {
  currentPage.value = 1
  fetchData()
}, 300)

// 监听搜索输入
watch(searchText, () => {
  debouncedSearch()
})

// 监听状态筛选
watch(currentStatus, () => {
  currentPage.value = 1
  fetchData()
})

// 监听机构筛选
watch(selectedOrg, () => {
  currentPage.value = 1
  fetchData()
})



// 机构筛选变化处理
const handleOrgFilter = () => {
  currentPage.value = 1
  fetchData()
}
// 校验密码的函数
const isValidPassword = (password) => {
  // 这里可以根据实际需求进行密码校验
  // 例如：返回 false 表示密码无效
  return password && password.length >= 6; // 示例：密码长度至少为6
};

// 修改密码的请求
const resetPassword = async () => {
  if (newPassword.value.length < 6) {
    errorMessage.value = '密码长度至少为6位';
    return;
  }
  
  try {
    const axios = createAxiosInstance("数据服务器");
    const response = await axios.post('/reset_password/', {
      passWord: md5(newPassword.value),
      userName:user.value.phoneNumber
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    
    if (response.status === 200) {
      ElMessage.success('密码修改成功');
      // localStorage.setItem('password', newPassword.value); // 更新本地存储的密码
      showPasswordDialog.value = false; // 关闭对话框
      localStorage.removeItem('authToken');
      localStorage.removeItem('password');
      router.push('/login');
    }
  } catch (error) {
    ElMessage.error('密码修改失败，请重试');
    console.error('密码修改错误:', error);
  }
};

// 添加批量处理相关的响应式变量
const selectedBeds = ref([]);
const isAllSelected = ref(false);
const isBatchMode = ref(false); // 控制批量模式的状态

// 切换批量模式
const toggleBatchMode = () => {
  isBatchMode.value = !isBatchMode.value;
  if (!isBatchMode.value) {
    // 退出批量模式时重置选择状态
    isAllSelected.value = false;
    filteredBeds.value.forEach(bed => {
      bed.selected = false;
    });
    selectedBeds.value = [];
  }
};

// 更新选中的设备列表
const updateSelectedBeds = () => {
  selectedBeds.value = filteredBeds.value.filter(bed => bed.selected);
  // 检查是否所有设备都被选中
  isAllSelected.value = filteredBeds.value.length > 0 && 
    filteredBeds.value.every(bed => bed.selected);
};

// 切换全选状态
const toggleSelectAll = () => {
  isAllSelected.value = !isAllSelected.value;
  filteredBeds.value.forEach(bed => {
    bed.selected = isAllSelected.value;
  });
  updateSelectedBeds();
};

// 下载选中设备的报告
const downloadReports = async () => {
  if (selectedBeds.value.length === 0) {
    ElMessage.warning('请选择至少一个设备');
    return;
  }
  const apiBaseUrl = "https://data.wuji.com/b/report/download";
  const devices = selectedBeds.value.map(bed => bed.number);
  try {
    const rawData = await fetchDataForDevices(apiBaseUrl, devices);
    const processedData = processData(rawData);
    saveToExcel(processedData);
    ElMessage.success('报告下载成功');
  } catch (error) {
    console.error('下载报告失败:', error);
    ElMessage.error('下载报告失败，请重试');
  }
};



// 时间戳转换为 yyyy-mm-dd HH:MM:SS 格式
const formatTimestampToDatetime = (timestamp) => {
  return new Date(timestamp * 1000).toISOString().replace('T', ' ').split('.')[0];
};

// 从接口中获取设备数据
const fetchDataForDevices = async (apiBaseUrl, devices) => {
  const allDeviceData = [];
  for (const deviceId of devices) {
    let apiUrl;
    
    // 根据日期范围构建 URL
    if (dateRange.value && dateRange.value[0] && dateRange.value[1]) {
      // 如果选择了日期范围
      const startDate = dateRange.value[0];
      const endDate = dateRange.value[1];
      apiUrl = `${apiBaseUrl}/${deviceId}/range/?start_date=${startDate}&end_date=${endDate}`;
    } else {
      // 如果没有选择日期范围，则获取所有历史数据
      apiUrl = `${apiBaseUrl}/${deviceId}/history/`;
    }
    
    console.log(`Fetching data for device: ${deviceId} from ${apiUrl}`);
    try {
      const axios = createAxiosInstance("计算服务器1");
      const response = await axios.get(apiUrl);
      if (response.status === 200) {
        const deviceData = response.data.data || [];
        deviceData.forEach(record => {
          record.device = deviceId; // 将设备ID加入记录中
        });
        allDeviceData.push(...deviceData); // 合并所有设备数据
      } else {
        console.error(`Failed to fetch data for ${deviceId}: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Error fetching data for ${deviceId}:`, error);
    }
  }
  return allDeviceData;
};

// 将 JSON 数据格式化为 Excel 数据
const processData = (data) => {
  const processedData = {};

  data.forEach(record => {
    const deviceId = record.device;
    if (!processedData[deviceId]) {
      processedData[deviceId] = [];
    }

    const row = {
      "日期": record.date,
      "设备ID": record.device,
      "关灯时间": formatTimestampToDatetime(record.reports.online.sleep_intention_time),
      "开灯时间": formatTimestampToDatetime(record.reports.online.intention_leave_time),
      "总睡眠时长 (分钟)": record.reports.online.tst,
      "卧床时长 (分钟)": record.reports.online.tib,
      "入睡时间 (分钟)": record.reports.online.sol,
      "睡眠中断 (分钟)": record.reports.online.waso,
      "深度睡眠 (分钟)": record.reports.online.deep_time,
      "快速眼动潜伏期 (分钟)": record.reports.online.rem_latency,
      "睡眠规律性 (%)": record.reports.online.sri,
      "睡眠碎片化指数": record.reports.online.sfi,
      "清醒 (分钟)": record.reports.online.sleep_dur_1,
      "快速眼动睡眠 (分钟)": record.reports.online.rem_time,
      "浅度睡眠 (分钟)": record.reports.online.core_time,
      "呼吸暂停低通指数 (AHI)": record.reports.online.ahi,
      "NREM AHI": record.reports.online.ahi_345,
      "REM AHI": record.reports.online.ahi_2,
      "鼾声指数 (SI)": record.reports.online.bcgsnore_index,
      "平均呼吸率 (次/分)": record.reports.online.avg_resp,
      "最低心率 (次/分)": record.reports.summmary.min_sleep_heart_rate,
      "平均心率 (次/分)": record.reports.summmary.avg_sleep_heart_rate,
      "HRV_RMSSD (毫秒)": record.reports.summmary.avg_hrv_rmssd,
      "HRV_LF/HF": record.reports.summmary.avg_hrv_radio,
      "平均温度 (°C)": record.reports.summmary.avg_temperature,
      "平均湿度 (%)": record.reports.summmary.avg_humidity,
      "手机号": record.reports.summmary.user,
      "姓名": record.reports.summmary.username,
      "身高": record.reports.summmary.height,
      "体重": record.reports.summmary.weight,
      "性别": record.reports.summmary.sex,
      "出生日期": record.reports.summmary.birthdate,
    };
    processedData[deviceId].push(row);
  });

  return processedData;
};

// 保存为 Excel（不同 Sheet）
const saveToExcel = (processedData) => {
  const workbook = XLSX.utils.book_new();
  for (const [deviceId, records] of Object.entries(processedData)) {
    const worksheet = XLSX.utils.json_to_sheet(records);
    XLSX.utils.book_append_sheet(workbook, worksheet, `设备_${deviceId}`);
  }
  const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '睡眠报告_多设备.xlsx');
};

// 日期范围
const dateRange = ref([]);
const showDatePicker = ref(false);

// 处理日期范围变化
const handleDateRangeChange = (newRange) => {
  console.log('Selected date range:', newRange);
};

// 准备下载报告
const prepareDownloadReports = () => {
  if (selectedBeds.value.length === 0) {
    ElMessage.warning('请选择至少一个设备');
    return;
  }
  showDatePicker.value = true;
  console.log('Dialog should open:', showDatePicker.value); // 添加调试日志
};

// 确认下载
const confirmDownload = async () => {
  showDatePicker.value = false;
  await downloadReports();
};

// 添加问卷选择对话框
const showQuestionnaire = ref(false)

// 显示问卷选择对话框
const showQuestionnaireDialog = () => {
  if (!selectedBed.value) {
    ElMessage.warning('请先选择一个设备')
    return
  }
  showQuestionnaire.value = true
}

// 打开具体的问卷
const openQuestionnaire = (type) => {
  showQuestionnaire.value = false
  if (type === 'psqi') {
    showPSQI.value = true
  } else if (type === 'ess') {
    showESS.value = true
  }
}
const showPSQI = ref(false)
const showESS = ref(false)
// 处理问卷提交
const handleQuestionnaireSubmit = () => {
  showPSQI.value = false
  showESS.value = false
  // 可以在这里添加刷新数据等操作
  ElMessage.success('问卷提交成功')
  // 如果需要刷新数据
  fetchData()
}

</script>

<style>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding-top: 20px;
}

/* 添加一些辅助样式 */
.el-form-item {
  margin-bottom: 18px;
}

.el-form-item__label {
  font-weight: normal;
  color: #606266;
}

.el-input {
  width: 100%;
}

.el-input.is-disabled .el-input__inner {
  color: #606266;
  background-color: #f5f7fa;
}

.el-input-number.is-controls-right .el-input__inner {
  padding-left: 8px;
  padding-right: 35px;
}
.el-input-number {
  width: 160px !important; /* 强制设置最小宽度 */
}
/* 调整表单项宽度 */
.el-select {
  width: 100%;
}

.el-date-picker {
  width: auto;
}

/* 确保年龄标签不换行 */
.whitespace-nowrap {
  white-space: nowrap;
}

/* 调整表单项间距 */
.el-form-item__label {
  font-weight: normal;
  color: #606266;
}

/* 调整日期选择器的样式 */
.el-date-picker {
  font-family: system-ui, -apple-system, sans-serif;
}

/* 确保单位标签有固定宽度且对齐 */
.unit-label {
  min-width: 2rem;
  text-align: left;
}

/* 调整数字输入框的样式 */
.el-input-number.is-controls-right .el-input__inner {
  padding-left: 8px;
  padding-right: 35px;
}

/* 添加新的样式 */
.custom-field-item {
  margin-bottom: 0.75rem !important;
  padding: 0.5rem 0.75rem !important;
  background-color: #f9fafb;
  border-radius: 0.5rem;
}

.custom-field-item .el-input {
  width: auto;
}

.custom-field-item .text-gray-900 {
  font-size: 14px;
  font-weight: 500;
}

.custom-field-item .text-gray-600 {
  font-size: 14px;
}

/* 调整按钮样式 */
.custom-field-item button {
  transition: all 0.2s ease;
}

.custom-field-item button:hover {
  transform: scale(1.1);
}

/* 微调间距 */
.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.gap-1 {
  gap: 0.25rem;
}

/* 添加分页相关样式 */
.el-pagination {
  justify-content: flex-end;
}

.el-select-dropdown__item {
  padding: 0 12px;
}

/* 优化分页组件样式 */
:deep(.el-pagination) {
  justify-content: center;
  white-space: nowrap;
}

:deep(.el-pagination.is-background .el-pager li:not(.is-disabled).is-active) {
  background-color: #3b82f6;
  border-color: #3b82f6;
}

:deep(.el-pagination.is-background .el-pager li:not(.is-disabled):hover) {
  color: #3b82f6;
}

/* 可以添加一些过渡效果 */
.transition-colors {
  transition: all 0.2s ease-in-out;
}

/* 优化下拉框样式 */
:deep(.el-select) {
  --el-select-input-focus-border-color: #3b82f6;
}

:deep(.el-select:hover .el-input__wrapper) {
  box-shadow: 0 0 0 1px #3b82f6 inset;
}

/* 优化状态按钮过渡效果 */
.transition-colors {
  transition: all 0.2s ease-in-out;
}

/* 添加一些样式来确保弹窗正确显示 */
.el-dialog {
  margin-top: 15vh !important;
}

.el-date-picker {
  width: 100% !important;
}

.dialog-footer {
  margin-top: 20px;
  text-align: right;
}

/* 确保弹窗内容可以滚动 */
:deep(.el-dialog__body) {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
  