<template>
  <div class="max-w-4xl mx-auto p-4">
    <div class="bg-white rounded-lg shadow-md p-6">
      <!-- 问卷标题和说明 -->
      <header class="mb-8 text-center border-b pb-4">
        <h1 class="text-2xl font-bold mb-3 text-gray-800">匹兹堡睡眠质量指数 (PSQI)</h1>
        <p class="text-gray-600 max-w-2xl mx-auto">
          下面一些问题是关于您最近1个月的睡眠情况，请选择或填写最符合您近1个月实际情况的答案。
        </p>
      </header>

      <el-form ref="formRef" :model="formData" label-position="top" class="space-y-8">
        <!-- 所有问题使用统一的样式 -->
        <div class="bg-gray-50 p-8 rounded-lg space-y-8">
          <el-form-item label="1. 近1个月，您通常晚上几点上床睡觉？" class="form-item-custom">
            <el-select
              v-model="formData.bedTime"
              placeholder="选择时间"
              class="custom-select"
            >
              <el-option
                v-for="time in bedTimeOptions"
                :key="time"
                :label="time"
                :value="time"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="2. 近1个月，从上床到入睡通常需要多少时间？" class="form-item-custom">
            <el-select 
              v-model="formData.sleepLatency" 
              placeholder="请选择入睡时间"
              class="custom-select"
            >
              <el-option 
                v-for="item in sleepLatencyOptions" 
                :key="item.value" 
                :label="item.label" 
                :value="item.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="3. 近1个月，您通常早上几点起床？" class="form-item-custom">
            <el-select
              v-model="formData.wakeTime"
              placeholder="选择时间"
              class="custom-select"
            >
              <el-option
                v-for="time in wakeTimeOptions"
                :key="time"
                :label="time"
                :value="time"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="4. 近1个月，每夜通常实际睡眠多少小时（不等于卧床时间）？" class="form-item-custom">
            <div class="flex items-center">
              <el-input-number 
                v-model="formData.sleepHours"
                :min="0"
                :max="12"
                :step="0.5"
                controls-position="right"
                class="custom-number-input"
              />
              <span class="ml-2 text-gray-600">小时</span>
            </div>
          </el-form-item>

          <el-form-item label="5. 近1个月，您经常出现以下情况吗？" class="form-item-custom">
            <div class="sub-questions space-y-4 mt-4 ml-6">
              <el-form-item label="a) 入睡困难" class="mb-2">
                <el-radio-group v-model="formData.sleepIssues[0]" class="ml-4">
                  <el-radio :label="0">无</el-radio>
                  <el-radio :label="1">&lt;1次/周</el-radio>
                  <el-radio :label="2">1-2次/周</el-radio>
                  <el-radio :label="3">≥3次/周</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="b) 夜间易醒或早醒" class="mb-2">
                <el-radio-group v-model="formData.sleepIssues[1]" class="ml-4">
                  <el-radio :label="0">无</el-radio>
                  <el-radio :label="1">&lt;1次/周</el-radio>
                  <el-radio :label="2">1-2次/周</el-radio>
                  <el-radio :label="3">≥3次/周</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="c) 夜间去厕所" class="mb-2">
                <el-radio-group v-model="formData.sleepIssues[2]" class="ml-4">
                  <el-radio :label="0">无</el-radio>
                  <el-radio :label="1">&lt;1次/周</el-radio>
                  <el-radio :label="2">1-2次/周</el-radio>
                  <el-radio :label="3">≥3次/周</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="d) 呼吸不畅" class="mb-2">
                <el-radio-group v-model="formData.sleepIssues[3]" class="ml-4">
                  <el-radio :label="0">无</el-radio>
                  <el-radio :label="1">&lt;1次/周</el-radio>
                  <el-radio :label="2">1-2次/周</el-radio>
                  <el-radio :label="3">≥3次/周</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="e) 咳嗽或鼾声高" class="mb-2">
                <el-radio-group v-model="formData.sleepIssues[4]" class="ml-4">
                  <el-radio :label="0">无</el-radio>
                  <el-radio :label="1">&lt;1次/周</el-radio>
                  <el-radio :label="2">1-2次/周</el-radio>
                  <el-radio :label="3">≥3次/周</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="f) 感觉冷" class="mb-2">
                <el-radio-group v-model="formData.sleepIssues[5]" class="ml-4">
                  <el-radio :label="0">无</el-radio>
                  <el-radio :label="1">&lt;1次/周</el-radio>
                  <el-radio :label="2">1-2次/周</el-radio>
                  <el-radio :label="3">≥3次/周</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="g) 感觉热" class="mb-2">
                <el-radio-group v-model="formData.sleepIssues[6]" class="ml-4">
                  <el-radio :label="0">无</el-radio>
                  <el-radio :label="1">&lt;1次/周</el-radio>
                  <el-radio :label="2">1-2次/周</el-radio>
                  <el-radio :label="3">≥3次/周</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="h) 做恶梦" class="mb-2">
                <el-radio-group v-model="formData.sleepIssues[7]" class="ml-4">
                  <el-radio :label="0">无</el-radio>
                  <el-radio :label="1">&lt;1次/周</el-radio>
                  <el-radio :label="2">1-2次/周</el-radio>
                  <el-radio :label="3">≥3次/周</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="i) 疼痛不适" class="mb-2">
                <el-radio-group v-model="formData.sleepIssues[8]" class="ml-4">
                  <el-radio :label="0">无</el-radio>
                  <el-radio :label="1">&lt;1次/周</el-radio>
                  <el-radio :label="2">1-2次/周</el-radio>
                  <el-radio :label="3">≥3次/周</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="j) 其它影响睡眠的事情" class="mb-2">
                <el-radio-group v-model="formData.sleepIssues[9]" class="ml-4">
                  <el-radio :label="0">无</el-radio>
                  <el-radio :label="1">&lt;1次/周</el-radio>
                  <el-radio :label="2">1-2次/周</el-radio>
                  <el-radio :label="3">≥3次/周</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-form-item>

          <el-form-item label="6. 近1个月，您如何评价自己的睡眠质量？" class="form-item-custom">
            <el-radio-group v-model="formData.sleepQuality">
              <el-radio :label="0">很好</el-radio>
              <el-radio :label="1">较好</el-radio>
              <el-radio :label="2">较差</el-radio>
              <el-radio :label="3">很差</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="7. 近1个月，您使用过以下药物吗？" class="form-item-custom">
            <el-radio-group v-model="formData.medicineUse">
              <el-radio :label="0">无</el-radio>
              <el-radio :label="1">&lt;1次/周</el-radio>
              <el-radio :label="2">1-2次/周</el-radio>
              <el-radio :label="3">≥3次/周</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="8. 近1个月，您常感到困倦吗？" class="form-item-custom">
            <el-radio-group v-model="formData.daytimeDrowsy">
              <el-radio :label="0">无</el-radio>
              <el-radio :label="1">&lt;1次/周</el-radio>
              <el-radio :label="2">1-2次/周</el-radio>
              <el-radio :label="3">≥3次/周</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="9. 近1个月，您做事情的精力不足吗？" class="form-item-custom">
            <el-radio-group v-model="formData.lackEnergy">
              <el-radio :label="0">没有</el-radio>
              <el-radio :label="1">偶尔有</el-radio>
              <el-radio :label="2">有时有</el-radio>
              <el-radio :label="3">经常有</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>

        <!-- 操作按钮 -->
        <div class="flex justify-end gap-4 mt-6 pt-4 border-t">
          <el-button @click="$emit('close')" class="custom-button">取消</el-button>
          <el-button type="primary" @click="handleSubmit" class="custom-button-primary">保存</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import {createAxiosInstance, wdb_get_history_new,request_data} from "@/plugins/axios.js";
import { emitter } from '@/plugins/eventBus'

const route = useRoute()
const router = useRouter()
const formRef = ref(null)
const props = defineProps({
  deviceId: {
    type: String,
    required: true
  },
  date: {
    type: String,
    required: true
  }
})
// 睡眠问题列表
const sleepIssues = [
  { label: 'a. 入睡困难（30分钟内不能入睡）' },
  { label: 'b. 夜间易醒或早醒' },
  { label: 'c. 夜间去厕所' },
  { label: 'd. 呼吸不畅' },
  { label: 'e. 咳嗽或鼾声高' },
  { label: 'f. 感觉冷' },
  { label: 'g. 感觉热' },
  { label: 'h. 做恶梦' },
  { label: 'i. 疼痛不适' },
  { label: 'j. 其它影响睡眠的事情' }
]

// 评分标签
const scoreLabels = {
  componentA: 'A. 睡眠质量',
  componentB: 'B. 入睡时间',
  componentC: 'C. 睡眠时间',
  componentD: 'D. 睡眠效率',
  componentE: 'E. 睡眠障碍',
  componentF: 'F. 催眠药物',
  componentG: 'G. 日间功能障碍'
}

// 表单数据
const formData = ref({
  bedTime: '',
  sleepLatency: null,
  wakeTime: '',
  sleepHours: null,
  sleepIssues: new Array(10).fill(null),
  sleepQuality: null,
  medicineUse: null,
  daytimeDrowsy: null,
  lackEnergy: null
})

// 添加入睡时间选项
const sleepLatencyOptions = [
  { value: 15, label: '15分钟以内' },
  { value: 30, label: '16-30分钟' },
  { value: 45, label: '31-45分钟' },
  { value: 60, label: '46-60分钟' },
  { value: 90, label: '61-90分钟' },
  { value: 120, label: '91-120分钟' },
  { value: 150, label: '121-150分钟' },
  { value: 180, label: '150分钟以上' }
]

// 生成时间选项数组
const generateTimeOptions = (startHour, startMinute) => {
  const options = []
  let hour = startHour
  let minute = startMinute
  
  for (let i = 0; i < 48; i++) { // 24小时，每半小时一个选项
    const timeStr = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`
    options.push(timeStr)
    
    minute += 30
    if (minute >= 60) {
      minute = 0
      hour += 1
      if (hour >= 24) {
        hour = 0
      }
    }
  }
  
  return options
}

// 生成就寝时间选项（从18:00开始）
const bedTimeOptions = generateTimeOptions(18, 0)

// 生成起床时间选项（从06:00开始）
const wakeTimeOptions = generateTimeOptions(4, 0)

// 计算各组成部分分数
const componentScores = computed(() => {
  const scores = {
    componentA: formData.value.sleepQuality,
    componentB: calculateComponentB(),
    componentC: calculateSleepTimeScore(),
    componentD: calculateSleepEfficiency(),
    componentE: calculateSleepDisturbance(),
    componentF: formData.value.medicineUse,
    componentG: calculateDaytimeFunction()
  }
  return scores
})

// 计算总分
const psqiScore = computed(() => {
  return Object.values(componentScores.value).reduce((sum, score) => sum + score, 0)
})

// 计算入睡时间得分
const calculateComponentB = () => {
  if (formData.value.sleepLatency === null) return 0
  
  // 直接使用选择的分钟数
  const minutes = formData.value.sleepLatency
  
  // 计算入睡时间得分
  const latencyScore = minutes <= 15 ? 0 :
                      minutes <= 30 ? 1 :
                      minutes <= 60 ? 2 : 3
                      
  const difficultyScore = formData.value.sleepIssues[0] || 0
  const sum = latencyScore + difficultyScore
  return sum <= 0 ? 0 : sum <= 2 ? 1 : sum <= 4 ? 2 : 3
}

// 计算睡眠时间得分
const calculateSleepTimeScore = () => {
  const hours = formData.value.sleepHours
  return hours > 7 ? 0 :
         hours >= 6 ? 1 :
         hours >= 5 ? 2 : 3
}

// 计算睡眠效率得分
const calculateSleepEfficiency = () => {
  if (!formData.value.bedTime || !formData.value.wakeTime) return 0
  
  const bedTime = new Date(`2000/01/01 ${formData.value.bedTime}`)
  const wakeTime = new Date(`2000/01/01 ${formData.value.wakeTime}`)
  let timeInBed = (wakeTime - bedTime) / (1000 * 60 * 60)
  if (timeInBed < 0) timeInBed += 24
  
  const efficiency = (formData.value.sleepHours / timeInBed) * 100
  return efficiency >= 85 ? 0 :
         efficiency >= 75 ? 1 :
         efficiency >= 65 ? 2 : 3
}

// 计算睡眠障碍得分
const calculateSleepDisturbance = () => {
  const sum = formData.value.sleepIssues.slice(1).reduce((acc, val) => acc + val, 0)
  return sum === 0 ? 0 :
         sum <= 9 ? 1 :
         sum <= 18 ? 2 : 3
}

// 计算日间功能得分
const calculateDaytimeFunction = () => {
  const sum = formData.value.daytimeDrowsy + formData.value.lackEnergy
  return sum === 0 ? 0 :
         sum <= 2 ? 1 :
         sum <= 4 ? 2 : 3
}

// 修改日期格式化函数
const formatDate = (dateString) => {
  if (!dateString) return ''
  // 如果包含 %20，说明是完整的时间戳，需要截取日期部分
  if (dateString.includes('%20')) {
    return dateString.split('%20')[0]
  }
  // 如果已经是 YYYY-MM-DD 格式，直接返回
  if (dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
    return dateString
  }
  // 其他情况，转换为 YYYY-MM-DD 格式
  const date = new Date(dateString)
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
}

// 修改重置表单函数
const resetForm = () => {
  formData.value = {
    bedTime: '',
    sleepLatency: null,
    wakeTime: '',
    sleepHours: null,
    sleepIssues: new Array(10).fill(null),
    sleepQuality: null,
    medicineUse: null,
    daytimeDrowsy: null,
    lackEnergy: null
  }
}

// 修改获取历史数据函数
const fetchQuestionnaireData = async () => {
  try {
    const formattedDate = formatDate(props.date)
    const axios = createAxiosInstance('计算服务器1')
    const response = await axios.get(`/questionnaire/${props.deviceId}/${formattedDate}/psqi`)
    
    if (response.data.status === 'success' && response.data.data?.data) {
      const { data } = response.data.data
      formData.value = {
        bedTime: data.bedTime || '',
        sleepLatency: data.sleepLatency ?? null,
        wakeTime: data.wakeTime || '',
        sleepHours: data.sleepHours ?? null,
        sleepIssues: Array.from({ length: 10 }, (_, i) => 
          data.sleepIssues && data.sleepIssues[i] !== undefined 
            ? data.sleepIssues[i] 
            : null
        ),
        sleepQuality: data.sleepQuality ?? null,
        medicineUse: data.medicineUse ?? null,
        daytimeDrowsy: data.daytimeDrowsy ?? null,
        lackEnergy: data.lackEnergy ?? null
      }
    } else {
      // 如果没有数据，重置表单
      resetForm()
    }
  } catch (error) {
    if (error.response?.status === 404) {
      // 如果是 404 错误，说明该日期没有数据，重置表单
      resetForm()
    } else {
      console.error('获取问卷数据失败:', error)
    }
  }
}

// 添加日期监听
watch(() => props.date, () => {
  // 日期变化时重新获取数据
  fetchQuestionnaireData()
})

// 修改表单验证函数，添加更详细的验证
const validateForm = () => {
  // 检查必填字段
  const requiredFields = {
    bedTime: '睡觉时间',
    wakeTime: '起床时间',
    sleepLatency: '入睡时间',
    sleepHours: '睡眠时间',
    sleepQuality: '睡眠质量评估',
    medicineUse: '药物使用情况',
    daytimeDrowsy: '日间嗜睡情况',
    lackEnergy: '精力情况'
  }

  for (const [field, label] of Object.entries(requiredFields)) {
    if (formData.value[field] === null || formData.value[field] === '') {
      ElMessage.warning(`请填写${label}`)
      return false
    }
  }

  // 检查睡眠问题数组
  if (formData.value.sleepIssues.some(item => item === null || item === undefined)) {
    ElMessage.warning('请完整填写所有睡眠问题的评估')
    return false
  }

  return true
}
const emit = defineEmits(['close', 'submit'])

// 修改数据验证函数
const validateData = (data) => {
  return {
    bedTime: data.bedTime || '',
    sleepLatency: typeof data.sleepLatency === 'number' ? data.sleepLatency : null,
    wakeTime: data.wakeTime || '',
    sleepHours: typeof data.sleepHours === 'number' ? data.sleepHours : null,
    sleepIssues: Array.isArray(data.sleepIssues) ? 
      data.sleepIssues.map(v => typeof v === 'number' ? v : null) : 
      new Array(10).fill(null),
    sleepQuality: typeof data.sleepQuality === 'number' ? data.sleepQuality : null,
    medicineUse: typeof data.medicineUse === 'number' ? data.medicineUse : null,
    daytimeDrowsy: typeof data.daytimeDrowsy === 'number' ? data.daytimeDrowsy : null,
    lackEnergy: typeof data.lackEnergy === 'number' ? data.lackEnergy : null
  }
}

// 修改提交问卷函数
const handleSubmit = async () => {
  try {
    if (!validateForm()) {
      return
    }

    const formattedDate = formatDate(props.date)
    const submitData = {
      data: validateData(formData.value),  // 使用验证函数处理数据
      scores: componentScores.value,
      totalScore: psqiScore.value
    }

    const axios = createAxiosInstance('计算服务器1')
    const response = await axios.post(
      `/questionnaire/${props.deviceId}/${formattedDate}/psqi`, 
      submitData
    )
    console.log(response)
    if (response.data.status === 'success') {
      ElMessage.success('问卷保存成功')
      // 触发全局事件
      emitter.emit('questionnaire-updated', {
        type: 'psqi',
        date: formattedDate,
        deviceId: props.deviceId
      })
      emit('submit')
    }
  } catch (error) {
    console.error('保存问卷失败:', error)
    ElMessage.error('保存失败，请重试')
  }
}

// 取消
const handleCancel = () => {
  router.back()
}

// 更新时间选择器的起止时间
onMounted(() => {
  fetchQuestionnaireData()
})
</script>

<style scoped>
/* 统一所有表单项样式 */
.form-item-custom :deep(.el-form-item__label) {
  @apply text-gray-700 font-medium mb-2;
  font-size: 15px;
}

/* 自定义选择器样式 */
.custom-select {
  width: 160px !important;
}

.custom-select :deep(.el-input__wrapper) {
  @apply shadow-sm border border-gray-300;
  box-shadow: none !important;
}

.custom-select :deep(.el-input__wrapper:hover) {
  @apply border-blue-400;
}

/* 自定义数字输入框样式 */
.custom-number-input :deep(.el-input__wrapper) {
  @apply shadow-sm border border-gray-300;
  box-shadow: none !important;
}

/* 子问题样式优化 */
.sub-questions :deep(.el-form-item__label) {
  @apply text-gray-600 font-normal;
  font-size: 14px;
}

/* 统一单选按钮组样式 */
:deep(.el-radio-group) {
  @apply flex flex-wrap gap-12;
  margin-top: 0.5rem;
}

:deep(.el-radio) {
  @apply mb-3;
  margin-right: 0;
  min-width: 120px;
}

:deep(.el-radio__label) {
  @apply text-gray-600;
  font-size: 14px;
}

/* 自定义按钮样式 */
.custom-button {
  @apply px-6 py-2 border border-gray-300 rounded-md transition-colors;
}

.custom-button:hover {
  @apply bg-gray-50;
}

.custom-button-primary {
  @apply px-6 py-2 bg-blue-500 text-white rounded-md transition-colors;
}

.custom-button-primary:hover {
  @apply bg-blue-600;
}

/* 确保所有表单组件对齐 */
:deep(.el-form-item__content) {
  @apply flex items-center;
}

/* 添加过渡效果 */
.el-select,
.el-input-number,
.el-radio-group {
  @apply transition-all duration-200;
}

/* 调整表单项间距 */
.form-item-custom {
  @apply mb-8 !important;
}

/* 调整子问题间距 */
.sub-questions {
  @apply space-y-4 mt-4;
}

/* 调整单选按钮组样式 */
:deep(.el-radio-group) {
  @apply flex flex-wrap gap-12;
  margin-top: 0.5rem;
}

:deep(.el-radio) {
  @apply mb-3;
  margin-right: 0;
  min-width: 120px;
}

/* 调整选择器和数字输入框的间距 */
.custom-select,
.custom-number-input {
  margin-top: 0.5rem;
}

/* 调整子问题的选项间距 */
.sub-questions :deep(.el-radio-group) {
  @apply gap-10;
}

.sub-questions :deep(.el-radio) {
  @apply mb-2;
}

/* 确保所有表单项标签和内容之间有足够间距 */
:deep(.el-form-item__label) {
  @apply mb-3 !important;
}
</style>