import {createRouter, createWebHistory} from 'vue-router'
import Login from './components/Login.vue'
import Dashboard from '@/components/Dashboard.vue'
import Register from "@/components/Register.vue"
import RegisterPhoneCode from "@/components/RegisterPhoneCode.vue"
import LoginPhoneCode from "@/components/LoginPhoneCode.vue"
import RetrievePassword from "@/components/RetrievePassword.vue"
import OrgDashboard from "@/components/OrgDashboard.vue"
import OrgReportDetail from "@/components/OrgReportDetail.vue"
import ReportList from "@/components/ReportList.vue"
import MainLayout from "@/components/MainLayout.vue"
import OrgPrintReport from "@/components/OrgPrintReport.vue"
import UserManagement from "@/components/UserManagement.vue"
// const routes = [
//     {path: '/', component: Login},
//     {path: '/dashboard', component: Dashboard},
//     {path: '/register', component: Register},
//     {path: '/register2', component: RegisterPhoneCode},
//     {path: '/login', component: LoginPhoneCode},
//     {path: '/retrieve', component: RetrievePassword},
//     {path: '/org-dashboard', component: OrgDashboard},
//     {path: '/reports',
//         name: 'ReportList',
//         component: ReportList,
//         meta: {
//             requiresAuth: true,
//             title: '报告列表'
//           }
//     },
    
//     {
//         path: '/bluetooth-config',
//         name: 'bluetooth-config',
//         component: () => import('@/components/BluetoothConfig.vue')
//       },
//     {
//         path: '/report',
//         name: 'report-detail', // 添加路由名称
//         component: OrgReportDetail,
//         props: (route) => {
//             // 添加错误处理和默认值
//             try {
//                 return {
//                     device: route.query.device || '',
//                     date: route.query.date || '',
//                     selections: route.query.selections ? JSON.parse(route.query.selections) : {},
//                     stage: 1
//                 }
//             } catch (error) {
//                 console.error('Error parsing route props:', error)
//                 return {
//                     device: '',
//                     date: '',
//                     selections: {},
//                     stage: 1
//                 }
//             }
//         }
//     },
// ];
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/register2',
    name: 'RegisterPhoneCode',
    component: RegisterPhoneCode
  },
  {
    path: '/login-phone',
    name: 'LoginPhoneCode',
    component: LoginPhoneCode
  },
  {
    path: '/retrieve',
    name: 'RetrievePassword',
    component: RetrievePassword
  },
  {
    path: '/questionnaire/:type/:deviceId/:date',
    name: 'questionnaire',
    component: () => import('@/components/QuestionnaireView.vue'),
    props: true
  },
  {
    path: '/',
    component: MainLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',  // 空路径会匹配 '/'
        redirect: '/devices'  // 登录后重定向到设备页
      },
      {
        path: 'devices',
        name: 'Devices',
        component: OrgDashboard
      },
      {
        path: 'reports',
        name: 'Reports',
        component: ReportList
      },
      {
        path: 'users',
        name: 'Users',
        component: UserManagement
      },
      {
        path: 'report',
        name: 'report-detail',
        component: OrgReportDetail,
        props: (route) => {
          try {
            return {
              device: route.query.device || '',
              date: route.query.date || '',
              selections: route.query.selections ? JSON.parse(route.query.selections) : {},
              stage: 1
            }
          } catch (error) {
            console.error('Error parsing route props:', error)
            return {
              device: '',
              date: '',
              selections: {},
              stage: 1
            }
          }
        }
      }
    ]
  },
      {
        path: '/report',
        name: 'report-detail', // 添加路由名称
        component: OrgPrintReport,
        props: (route) => {
            // 添加错误处理和默认值
            try {
                return {
                    device: route.query.device || '',
                    date: route.query.date || '',
                    selections: route.query.selections ? JSON.parse(route.query.selections) : {},
                    stage: 1
                }
            } catch (error) {
                console.error('Error parsing route props:', error)
                return {
                    device: '',
                    date: '',
                    selections: {},
                    stage: 1
                }
            }
        }
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes
})

// 添加全局导航守卫进行错误处理
router.beforeEach(async (to, from, next) => {
  const urlToken = to.query.token;
  
  // 添加一个标记，避免重复处理token
  if (urlToken && !to.query.processed) {
    try {
      // 先清除旧token
      // localStorage.clear();
      
      // 设置新token
      localStorage.setItem('authToken', urlToken);

      localStorage.setItem('authToken1', urlToken);
      
      // 验证token是否确实被设置
      const savedToken = localStorage.getItem('authToken');
      
      if (!savedToken) {
        throw new Error('Token 保存失败');
      }

      // 重定向时添加processed标记，避免重复处理
      next({
        path: '/devices',
        query: { processed: true }
      });
      return;
    } catch (error) {
      console.error('设置 token 时出错:', error);
      next('/login');
      return;
    }
  }

  const isAuthenticated = localStorage.getItem('authToken')
  
  const isAuthenticated1 = localStorage.getItem('authToken1')

  if(!isAuthenticated && isAuthenticated1){  
    localStorage.setItem('authToken', isAuthenticated1);
    console.log('isAuthenticated1', isAuthenticated1)
  }
  console.log('isAuthenticated', isAuthenticated)
  // 如果是根路径且未登录，重定向到登录页≥
  if (to.path === '/' && !isAuthenticated) {
    next('/login')
    return
  }
  
  // 检查路由是否需要认证
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next('/login')
      return
    }
  }
  
  // 如果已登录且访问登录页，重定向到首页
  if (isAuthenticated && to.path === '/login') {
    next('/')
    return
  }

  // 报告详情的验证逻辑
  if (to.name === 'report-detail') {
    const { device, date, selections } = to.query
    if (!device || !date || !selections) {
      console.error('Missing required query parameters')
      next('/devices')
      return
    }
  }
  
  next()
})

// 添加错误处理
router.onError((error) => {
    console.error('Router error:', error)
})

export default router
