<template>
  <div class="questionnaire-content">
    <div class="bg-white">
      <!-- 问卷说明 -->
      <header class="mb-8">
        <h1 class="text-2xl font-bold mb-4">Epworth 嗜睡量表 (ESS)</h1>
        <div class="text-gray-600 space-y-2">
          <p>在下列情况下你打瞌睡(不仅仅是感到疲倦)的可能如何？这是指你最近几月的通常生活情况。</p>
          <p>假如你最近没有做过其中的某些事情，请试着填上它们可能会给你带来多大的影响。</p>
          <!-- <p class="font-medium">评分标准：</p>
          <ul class="list-disc pl-5 space-y-1">
            <li>0分 - 从不打瞌睡</li>
            <li>1分 - 偶尔打瞌睡</li>
            <li>2分 - 经常打瞌睡</li>
            <li>3分 - 总是打瞌睡</li>
          </ul> -->
        </div>
      </header>

      <!-- 问卷内容 -->
      <el-form ref="formRef" :model="formData" label-position="top">
        <div class="border rounded-lg overflow-hidden">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th class="px-6 py-3 text-left text-sm font-medium text-gray-500">序号</th>
                <th class="px-6 py-3 text-left text-sm font-medium text-gray-500">情况描述</th>
                <th class="px-6 py-3 text-sm font-medium text-gray-500">评分</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(item, index) in essQuestions" :key="index" 
                  :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ index + 1 }}
                </td>
                <td class="px-6 py-4 text-sm text-gray-900">
                  {{ item.question }}
                </td>
                <td class="px-6 py-4">
                  <el-radio-group v-model="formData.answers[index]">
                    <el-radio :label="0">从不打瞌睡</el-radio>
                    <el-radio :label="1">偶尔打瞌睡</el-radio>
                    <el-radio :label="2">经常打瞌睡</el-radio>
                    <el-radio :label="3">总是打瞌睡</el-radio>
                  </el-radio-group>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 分数显示部分已隐藏 -->
        <!-- <div v-if="totalScore !== null" class="mt-6 p-4 bg-blue-50 rounded-lg">
          <h3 class="font-medium mb-2">ESS 评分结果：</h3>
          <p class="text-lg font-bold" :class="scoreColorClass">
            总分：{{ totalScore }} 分
          </p>
          <p class="mt-2 text-gray-600">{{ resultText }}</p>
        </div> -->

        <!-- 操作按钮 -->
        <div class="flex justify-end gap-4 mt-8">
          <el-button @click="$emit('close')">取消</el-button>
          <el-button type="primary" @click="handleSubmit">保存</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { ElMessage } from 'element-plus'
import { createAxiosInstance } from '@/plugins/axios.js'
import { emitter } from '@/plugins/eventBus'

const props = defineProps({
  deviceId: {
    type: String,
    required: true
  },
  date: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['close', 'submit'])

// ESS 问题列表
const essQuestions = [
  { question: '坐着看书、看报或看电视时' },
  { question: '坐着看电影或看演出时' },
  { question: '坐着不活动（如在公共场合、会议、剧场等）' },
  { question: '连续乘车超过1小时' },
  { question: '下午休息时躺下休息' },
  { question: '坐着与人交谈时' },
  { question: '午餐后安静坐着时（未饮酒）' },
  { question: '开车时遇到交通阻塞需要停车几分钟' }
]

// 表单数据
const formData = ref({
  answers: new Array(8).fill(null)
})

// 计算总分
const totalScore = computed(() => {
  if (formData.value.answers.includes(null)) return null
  return formData.value.answers.reduce((sum, score) => sum + score, 0)
})

// 计算分数颜色类
const scoreColorClass = computed(() => {
  if (totalScore.value === null) return ''
  if (totalScore.value <= 6) return 'text-green-600'
  if (totalScore.value <= 10) return 'text-yellow-600'
  if (totalScore.value <= 15) return 'text-orange-600'
  return 'text-red-600'
})

// 计算结果文本
const resultText = computed(() => {
  if (totalScore.value === null) return ''
  if (totalScore.value <= 6) return '正常范围：您的日间嗜睡程度在正常范围内。'
  if (totalScore.value <= 10) return '轻度嗜睡：您可能存在轻度的日间嗜睡。'
  if (totalScore.value <= 15) return '中度嗜睡：您存在中度的日间嗜睡，建议就医检查。'
  return '重度嗜睡：您存在严重的日间嗜睡，强烈建议就医检查。'
})

// 表单验证
const validateForm = () => {
  return !formData.value.answers.includes(null)
}

// 修改日期格式化函数
const formatDate = (dateString) => {
  if (!dateString) return ''
  // 如果包含 %20，说明是完整的时间戳，需要截取日期部分
  if (dateString.includes('%20')) {
    return dateString.split('%20')[0]
  }
  // 如果已经是 YYYY-MM-DD 格式，直接返回
  if (dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
    return dateString
  }
  // 其他情况，转换为 YYYY-MM-DD 格式
  const date = new Date(dateString)
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
}

// 添加重置表单函数
const resetForm = () => {
  formData.value = {
    answers: new Array(8).fill(null)
  }
}

// 提交问卷
const handleSubmit = async () => {
  try {
    if (!validateForm()) {
      ElMessage.warning('请完整填写所有问题')
      return
    }

    const formattedDate = formatDate(props.date)
    const submitData = {
      data: {
        answers: formData.value.answers
      },
      totalScore: totalScore.value
    }

    const axios = createAxiosInstance('计算服务器1')
    const response = await axios.post(
      `/questionnaire/${props.deviceId}/${formattedDate}/ess`,
      submitData
    )

    if (response.data.status === 'success') {
      ElMessage.success('问卷保存成功')
      // 触发全局事件
      emitter.emit('questionnaire-updated', {
        type: 'ess',
        date: formattedDate,
        deviceId: props.deviceId
      })
      emit('submit')
    }
  } catch (error) {
    console.error('保存问卷失败:', error)
    ElMessage.error('保存失败，请重试')
  }
}

// 修改获取历史数据函数
const fetchQuestionnaireData = async () => {
  try {
    const formattedDate = formatDate(props.date)
    const axios = createAxiosInstance('计算服务器1')
    const response = await axios.get(`/questionnaire/${props.deviceId}/${formattedDate}/ess`)
    
    if (response.data.status === 'success' && response.data.data) {
      const { data } = response.data.data
      formData.value = {
        answers: data.answers || new Array(8).fill(null)
      }
    } else {
      // 如果没有数据，重置表单
      resetForm()
    }
  } catch (error) {
    if (error.response?.status === 404) {
      // 如果是 404 错误，说明该日期没有数据，重置表单
      resetForm()
    } else {
      console.error('获取问卷数据失败:', error)
    }
  }
}

// 监听日期变化
watch(() => props.date, () => {
  // 日期变化时重新获取数据
  fetchQuestionnaireData()
})

// 组件挂载时获取数据
onMounted(() => {
  fetchQuestionnaireData()
})
</script>

<style scoped>
.questionnaire-content {
  max-height: 70vh;
  overflow-y: auto;
  padding: 1rem;
}

.el-radio-group {
  display: flex;
  gap: 1rem;
}

.el-radio {
  margin-right: 0;
}

.el-form-item {
  margin-bottom: 1.5rem;
}

/* 表格样式 */
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 12px;
  text-align: left;
}

tbody tr:hover {
  background-color: #f9fafb;
}
</style>
