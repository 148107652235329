<template>
  <div class="login-container">
    <!-- 左侧产品信息部分 -->
    <div class="left-side">
      <img src="@/assets/index.png" alt="睡眠监护仪" class="background-image">
      <div class="overlay"></div>
      <div class="title-container">
        <h1 class="welcome-text">欢迎使用</h1>
        <h2 class="product-name">五季医学睡眠监测工作站</h2>
      </div>
    </div>

    <!-- 右侧登录表单部分 -->
    <div class="right-side">
      <div class="login-form-box">
        <h2 class="login-title">登录账号</h2>
        <form @submit.prevent="handleLogin">
          <div class="form-item">
            <input
                type="tel"
                v-model="loginForm.username"
                placeholder="请输入账号"
                class="input-field"
            >
          </div>
          <div class="form-item">
            <input
                type="password"
                v-model="loginForm.password"
                placeholder="请输入密码"
                class="input-field"
            >
          </div>
          <div class="form-options">
            <label class="remember-me">
              <input type="checkbox" v-model="rememberPassword">
              <span>记住密码</span>
            </label>
            <!-- <a href="#" class="forgot-password" @click="password_btn">忘记密码？</a> -->
          </div>
          <span class="error-message" v-if="loginError">{{ loginError }}</span>

          <button type="submit" class="login-button">登录</button>
        </form>
        <!-- <div class="divider">
          <span class="divider-text">其他登录方式</span>
        </div> -->
        <!-- <div class="alternative-login">
          <button class="alt-login-btn" @click="login_btn">手机验证码登录</button>
          <button class="alt-login-btn" @click="register_btn">注册账号</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, ref, watch, defineEmits, onMounted} from "vue";

import {useRouter} from 'vue-router';

import {createAxiosInstance, wdb_get_new} from "@/plugins/axios.js"; // 假设这是你的axios实例创建函数
import {store} from "@/plugins/store.js"; // 假设这是你的axios实例创建函数
import md5 from 'md5';

const router = useRouter();
const rememberPassword = ref('')
const loginForm = ref({
  username: '',
  password: '',
});
const register_btn = async () => {
  router.push('/register'); //

}
const login_btn = async () => {
  router.push('/login'); //

}
const password_btn = async () => {
  router.push('/retrieve'); //
}
const loginError = ref(false);
const loginSuccessMessage = ref('');
onMounted(() => {
  // 从 localStorage 获取保存的用户名和密码
  const savedUsername = localStorage.getItem('savedUsername');
  const savedPassword = localStorage.getItem('savedPassword');
  if (savedUsername && savedPassword) {
    loginForm.value.username = savedUsername;
    loginForm.value.password = savedPassword;
    rememberPassword.value = true;
  }
});

const handleLogin = async (event) => {
  event.preventDefault(); // 阻止表单默认提交行为
  loginError.value = false; // 重置登录错误状态
  const LoginForm = {
    username: loginForm.value.username,
    password: md5(loginForm.value.password)
  };
  try {
    const axios = createAxiosInstance("数据服务器");
    const response = await axios.post('/login/', LoginForm, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    console.log(response.data);
    if (response.status === 200) {
      const token = response.data.data.token;  // 提取令牌
      const password = response.data.data.password;  // 提取令牌
      console.log(token);
      // 将 token 存入 localStorage
      localStorage.setItem('authToken', token);
      localStorage.setItem('password', password);

      loginSuccessMessage.value = '登录成功！正在加载用户信息...';
      loginError.value = false;
      store.dispatch('fetchUser'); // 调用Vuex action更新用户信息
      router.push('/devices')
    } else {
      localStorage.removeItem('authToken');
    }
    // 如果选择了“记住密码”，则保存用户名和密码到 localStorage
    if (rememberPassword.value) {
      localStorage.setItem('savedUsername', loginForm.value.username);
      localStorage.setItem('savedPassword', loginForm.value.password);
    } else {
      // 没有选择“记住密码”，则清除存储的用户名和密码
      localStorage.removeItem('savedUsername');
      localStorage.removeItem('savedPassword');
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // 特定处理401错误
      loginError.value = "登录失败。请输入正确的用户或密码，在非微信浏览器中使用";
    } else {
      // 处理其他错误
      loginError.value = "登录失败：" + error.message;
      localStorage.removeItem('authToken');
    }
    console.log("用户或密码错误");
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin-right: 10%;
}

/* 左侧样式 */
.left-side {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  margin-left: 10%;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  z-index: 2;
}

.title-container {
  position: relative;
  padding: 60px 0 0 60px;
  z-index: 3;
}

.welcome-text {
  color: #30729F;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
}

.product-name {
  color: #30729F;
  font-size: 32px;
  font-weight: bold;
}

/* 右侧登录表单样式 */
.right-side {
  margin-right: 20%;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  height: 100%;
  overflow-y: auto;
}

.login-form-box {
  width: 100%;
  padding: 40px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.login-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 500;
}

.form-item {
  margin-bottom: 20px;
}

.input-field {
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  font-size: 14px;
  transition: all 0.3s;
}

.input-field:focus {
  border-color: #30729F;
  outline: none;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.remember-me {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-size: 14px;
}

.forgot-password {
  color: #30729F;
  font-size: 14px;
  text-decoration: none;
}

.login-button {
  width: 100%;
  height: 40px;
  background: #30729F;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;
}

.login-button:hover {
  background: #30729F;
}

/* 修改分割线样式 */
.divider {
  position: relative;
  text-align: center;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider::before,
.divider::after {
  content: '';
  display: inline-block;
  height: 1px;
  background-color: #e8e8e8;
  flex: 1;
  margin: 0 10px; /* 调整文字和线之间的间距 */
}

.divider-text {
  color: #999;
  font-size: 14px;
  white-space: nowrap; /* 防止文字换行 */
}

.alternative-login {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.alt-login-btn {
  flex: 1;
  height: 36px;
  background: white;
  border: 1px solid #30729F;
  color: #30729F;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
}

.alt-login-btn:hover {
  background: #f0f7ff;
}

.error-message {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
  display: block;
}

@media (max-width: 1200px) {
  .right-side {
    width: 450px;
  }
}

@media (max-width: 992px) {
  .right-side {
    width: 400px;
  }

  .login-form-box {
    padding: 30px;
  }
}
</style>